import {
  addOrderCouponSuccess,
  addOrderCouponFailure,
  addPendingOrderItemToCart,
  openExistingOrderItem,
  removeOrderCoupon,
  setOrderComment,
  setPendingOrderItem,
  setOrderItems,
  removeOrderItem,
  changeOrderItemCount,
  clearCart,
} from './cart.actions';
import { closeModal, navigate } from '../session/session.actions';
import {
  AddOrderCouponFailurePayload,
  AddOrderCouponSuccessPayload,
  OpenExistingOrderItemPayload,
  SetOrderItemsPayload,
  SetPendingOrderItemPayload,
  RemoveOrderItemPayload,
  ChangeOrderItemCountPayload,
} from './cart.actions.types';
import { CloseModalPayload, NavigatePayload } from '../session/session.actions.types';
import { createReducer, pushItem, removeItem, replaceItem } from '../common/reducerUtils';

import { Modals, RouteUrls } from '../../core/constants';
import _ from 'lodash';
import { ActionHandlers, CouponStatus, OrderItem } from '@wix/restaurants-client-logic';

interface CartState {
  pendingOrderItem?: OrderItem;
  orderItems: OrderItem[];
  pendingItemIndex?: number;
  comment?: string;
  coupon?: CouponStatus;
}

const initialState: CartState = {
  pendingOrderItem: undefined,
  orderItems: [],
  pendingItemIndex: undefined,
};

const handlers: ActionHandlers<CartState> = {
  [setPendingOrderItem.toString()]: (state, { orderItem, isNew }: SetPendingOrderItemPayload) => ({
    ...state,
    pendingOrderItem: orderItem,
    pendingItemIndex: isNew ? undefined : state.pendingItemIndex,
  }),
  [setOrderItems.toString()]: (state, { orderItems }: SetOrderItemsPayload) => ({
    ...state,
    orderItems,
  }),
  [openExistingOrderItem.toString()]: (state, { itemIndex }: OpenExistingOrderItemPayload) => {
    return {
      ...state,
      pendingItemIndex: itemIndex,
      pendingOrderItem: state.orderItems[itemIndex],
    };
  },
  [addPendingOrderItemToCart.toString()]: (state) => {
    if (!state.pendingOrderItem) {
      return state;
    }
    if (state.pendingItemIndex !== undefined) {
      const newOrderItems = _.cloneDeep(state.orderItems);
      newOrderItems[state.pendingItemIndex] = state.pendingOrderItem;
      return {
        ...state,
        orderItems: newOrderItems,
        pendingOrderItem: undefined,
        pendingItemIndex: undefined,
      };
    }
    return {
      ...state,
      pendingOrderItem: undefined,
      orderItems: pushItem<OrderItem>(state.orderItems, state.pendingOrderItem),
    };
  },
  [setOrderComment.toString()]: (state, { comment }) => ({
    ...state,
    comment,
  }),
  [addOrderCouponSuccess.toString()]: (state, coupon: AddOrderCouponSuccessPayload) => ({
    ...state,
    coupon,
  }),
  [addOrderCouponFailure.toString()]: (state, coupon: AddOrderCouponFailurePayload) => ({
    ...state,
    coupon,
  }),
  [removeOrderCoupon.toString()]: (state) => ({
    ...state,
    coupon: undefined,
  }),
  [removeOrderItem.toString()]: (state, payload: RemoveOrderItemPayload) => ({
    ...state,
    orderItems: removeItem(state.orderItems, payload.itemIndex),
    pendingItemIndex: undefined,
    pendingOrderItem: undefined,
  }),
  [changeOrderItemCount.toString()]: (state, payload: ChangeOrderItemCountPayload) => ({
    ...state,
    orderItems: replaceItem(state.orderItems, payload.itemIndex, {
      ...state.orderItems[payload.itemIndex],
      count: payload.count,
    }),
  }),
  [closeModal.toString()]: (state, payload: CloseModalPayload) => {
    if (payload.modal === Modals.CART_MODAL && state.coupon?.type !== 'success') {
      return {
        ...state,
        coupon: undefined,
      };
    }

    return state;
  },
  [navigate.toString()]: (state, { routeUrl }: NavigatePayload) => {
    switch (routeUrl) {
      case RouteUrls.THANK_YOU:
        return initialState;
      default:
        return state;
    }
  },
  [clearCart.toString()]: () => {
    return initialState;
  },
};

const reducer = createReducer<CartState>(initialState, handlers);

export default reducer;
