import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import {
  evaluateCondition,
  isChargeRestrictedByCouponHashCode,
  calcOrderItemGrandTotal,
  OrderItem,
  ChargeV2,
  isRestrictedByOrderItems,
} from '@wix/restaurants-client-logic';
import { getConditionResult } from '../../components/MainPage/components/Cart/Cart.helper';

export const couponChargesSelector = createSelector(
  (state: AppState) => state.session.charges,
  (state: AppState) => state.session.restaurant.timezone,
  (state: AppState) => state.checkout.dispatch,
  (state: AppState) => state.platformParams.isMobile,
  (state: AppState) => state.cart.orderItems,
  (state: AppState) => state.cart.coupon,
  (charges, timezone, dispatch, isMobile, orderItems, coupon) => {
    if (coupon?.type !== 'success' || !coupon.couponHashCode) {
      return {};
    }

    const totalOrderPrice = orderItems.reduce((acc: number, curr: OrderItem) => acc + calcOrderItemGrandTotal(curr), 0);

    const chargesAndConditions = (charges || []).map((charge: ChargeV2) => {
      const params = {
        charge,
        condition: charge.condition,
        dispatchTime: dispatch.time ? moment(dispatch.time).tz(timezone) : moment().tz(timezone),
        dispatchType: dispatch.type,
        platform: isMobile ? 'mobileweb' : 'web',
        totalOrderPrice,
        couponHashCode: coupon.couponHashCode,
        orderItems,
      };

      let conditionResult = evaluateCondition(params.condition, params);
      conditionResult = getConditionResult({
        chargeState: charge.state,
        isRestrictedByOrderItems: isRestrictedByOrderItems(params),
        conditionResult,
      });

      return {
        charge,
        conditionResult,
      };
    });

    const relevantChargeConditions = chargesAndConditions.filter((cc) =>
      isChargeRestrictedByCouponHashCode(cc.charge, coupon.couponHashCode),
    );

    // Might be multiple relevant charges (discount is cloned for multiple tax groups)
    const chargeAndCondition =
      relevantChargeConditions.find((c) => c.conditionResult.result) || relevantChargeConditions[0];

    if (!chargeAndCondition) {
      return { error: { type: 'nonexistentCoupon' } };
    }

    if (!chargeAndCondition.conditionResult.result) {
      const reason = chargeAndCondition.conditionResult.reasons[0];
      return { charge: chargeAndCondition.charge, error: reason };
    }

    return { charge: chargeAndCondition.charge };
  },
);
