import { ProbeArgument } from '../createStore';
import { DeliveryDispatch } from '@wix/restaurants-client-logic';
import { revertAddress, setSelectedAddress, setAddressInputValue, setAddressInputError } from './addressForm.actions';
import { getCurrentPacingLevel } from '../session/session.actions';

export default function addressFormProbe({ onAction }: ProbeArgument) {
  onAction(revertAddress.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const checkoutAddress = (getState().checkout.dispatch as DeliveryDispatch).address;

    // NOTE: the order of dispatches is **important**.
    // We first want to revert the address input value and then the selected address

    dispatch(setAddressInputValue({ value: checkoutAddress.formatted }));
    dispatch(setSelectedAddress({ address: checkoutAddress }));
    dispatch(getCurrentPacingLevel(null));
  });
  onAction(setSelectedAddress.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(setAddressInputError({ validateAddressReason: undefined }));
  });
}
