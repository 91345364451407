import _ from 'lodash';
import stateVersion, { StateVersion } from './stateVersion/stateVersion.reducer';
import combinedReducers from './combinedReducers';
import { Action } from '@wix/restaurants-client-logic';

type AppState = ReturnType<typeof combinedReducers> & { stateVersion: StateVersion } & { [key: string]: any };

function rootReducer(state: AppState | undefined, action: Action<any>): AppState {
  const x = _.omit(state, 'stateVersion') as AppState;
  const nextState = combinedReducers(x, action);
  const nextStateVersion = stateVersion(state, nextState);

  return { ...nextState, stateVersion: nextStateVersion };
}

export default rootReducer;
