import { getSettingsValues, IWixStyleParams } from '@wix/tpa-settings';
import { default as applicationJson } from '../../../.application.json';
import {
  getOrganizationFullByAppDefAndInstance,
  getOrganizationFullByMsid,
  getPaymentMethods,
} from '../../core/oloApi';
import { AppState, createConfiguredStore } from '../../state/createStore';
import {
  setOrganizationFull,
  initApp,
  setPaymentMethods,
  setIsLocationPicked,
  setIsMultiLocation,
  setLoadingPaymentMethods,
  setSignedInstance,
} from '../../state/session/session.actions';
import {
  setPlatformParams,
  setPlatformParamsSettings,
  setPlatformParamsLabelsStyles,
} from '../../state/platformParams/platformParams.actions';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { Store } from 'redux';
import { extractInstanceData } from '../../core/logic/instanceLogic';
import { getBaseUrlForMappedServices } from '../../core/logic/urlLogic';
import { componentSettings } from './componentSettings';
import uuid from 'uuid';
import {
  Action,
  Address,
  Contact,
  CouponSuccess,
  Dispatch,
  Instance,
  OrderItem,
  LabelsSettings,
  getOrganizationAndMenu,
  getDisplayableMenu,
  getDisplayableCatalog,
  getSeoRestaurant,
  Restaurant,
  Menu,
  Location,
  fetchRestaurant,
  Catalog,
  CatalogPlatform,
  isMultiLocationSite,
  ORDERS_APP_ID,
} from '@wix/restaurants-client-logic';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/es/src/types/types';
import { EMPTY_CATALOG, SESSION_STORAGE_KEY } from '../../core/constants';
import { CheckoutStep } from '../../core/types/Checkout';
import _ from 'lodash';
import { loadStoredDataIntoStore } from '../../core/logic/sessionStorageDataHandler';
import moment from 'moment-timezone';
import { fetchCatalog } from '../../core/catalogApi';
import { fetchOrganizationData } from '@wix/bi-logger-olo-client/v2';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';

export const ORGANIZATION_FULL_WARMUPDATA_KEY = 'RESTAURANT_ORGANIZATION_FULL';
const SESSION_ID = uuid.v4();

interface Organization {
  restaurant: Restaurant;
  menu: Menu;
  catalog: Catalog;
  locations: Location[];
}

export interface OrganizationDeprecated {
  restaurant: Restaurant;
  menu: Menu;
  locations: Location[];
}

const getFromWarmUpData = (
  key: typeof ORGANIZATION_FULL_WARMUPDATA_KEY,
  flowAPI: ControllerFlowAPI,
): Organization | undefined => {
  return (
    flowAPI.controllerConfig.wixCodeApi.window.warmupData &&
    flowAPI.controllerConfig.wixCodeApi.window.warmupData.get(key) &&
    JSON.parse(flowAPI.controllerConfig.wixCodeApi.window.warmupData.get(key))
  );
};
const trySaveWarmupData = async (key: string, flowAPI: ControllerFlowAPI, data: any) => {
  const warmupData = flowAPI.controllerConfig.wixCodeApi.window.warmupData;
  if (flowAPI.controllerConfig.wixCodeApi.window.rendering.env === 'backend') {
    warmupData && warmupData.set(key, JSON.stringify(data));
  }
};

export interface EnvironmentData {
  language: string;
  store: Store<AppState, Action<any>>;
}

export interface StorageData {
  orderItems?: OrderItem[];
  coupon?: CouponSuccess;
  comment?: string;
  checkoutStep: CheckoutStep;
  contact: Contact;
  dispatch: Dispatch;
  selectedAddressOption: Address;
  timestamp: number;
  loyaltyPointsToRedeem?: number;
  selectedAddressId?: string;
  deliveryProvider?: { configurationId: string; fee: number; estimateId: string };
  calculatedFees?: CalculatedFee[];
  hasServiceFeesRules: boolean;
}

async function getOrganizationFull(
  metaSiteId: string,
  instanceId: string,
  appId: string,
  baseUrlForMappedServices: string,
  language: string,
) {
  let organizationFull: OrganizationDeprecated;
  if (!!instanceId && !!appId && appId === ORDERS_APP_ID) {
    // if appId equals to orders app guid (olo-client case) use the new api, o.w- use the old one
    organizationFull = await getOrganizationFullByAppDefAndInstance(instanceId, appId, language);
  } else {
    organizationFull = await getOrganizationFullByMsid(metaSiteId, baseUrlForMappedServices);
  }
  if (organizationFull) {
    organizationFull = { ...organizationFull, locations: [] };
  }
  return organizationFull;
}

async function getOrganizationFullWithCatalog(
  flowAPI: ControllerFlowAPI,
  baseUrlForMappedServices: string,
  locationId: string,
  metaSiteId: string,
  instanceId: string,
  appId: string,
  language: string,
  isCatalogsV3: boolean,
) {
  let organizationFull: OrganizationDeprecated | null | undefined;

  organizationFull = await fetchRestaurantAPI(flowAPI, baseUrlForMappedServices, locationId);

  if (!organizationFull) {
    flowAPI.fedops.interactionStarted('get-organization-and-menu');
    organizationFull = await getOrganizationAndMenu(getSignedInstance(flowAPI), locationId);
    flowAPI.fedops.interactionEnded('get-organization-and-menu');
  }

  if (!organizationFull) {
    organizationFull = await getOrganizationFull(metaSiteId, instanceId, appId, baseUrlForMappedServices, language);
  }

  if (!organizationFull) {
    throw new Error('CANNOT FETCH ORGANIZATION!');
  }

  if (!isCatalogsV3) {
    return { ...organizationFull, catalog: EMPTY_CATALOG };
  }

  const defaultLocationId = organizationFull.locations.find((location) => location.isDefault)?.locationId;
  const catalog: Catalog = await fetchCatalog(flowAPI, locationId || defaultLocationId);

  return { ...organizationFull, catalog };
}

export async function getPaymentMethodsAndSetLoading(
  appDefId: string,
  instanceId: string,
  locale: string,
  setLoading: (loading: boolean) => void,
) {
  setLoading(true);
  return getPaymentMethods(appDefId, instanceId, locale);
}

async function setup(flowAPI: ControllerFlowAPI): Promise<EnvironmentData> {
  const {
    experiments,
    environment: { language },
  } = flowAPI;
  const { metaSiteId, instanceId, appDefId: appId } = extractInstanceFromFlowAPI(flowAPI);
  const baseUrlForMappedServices = getBaseUrlForMappedServices({
    websiteUrl: flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
    environment: flowAPI.environment,
  });
  const locationId = flowAPI.controllerConfig.wixCodeApi.location.query?.locationId;
  const store = createConfiguredStore(undefined, { flowAPI });

  store.dispatch(setIsLocationPicked({ value: locationId ? true : false }));

  let organizationFull = getFromWarmUpData(ORGANIZATION_FULL_WARMUPDATA_KEY, flowAPI);

  const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');

  if (!organizationFull) {
    organizationFull = await getOrganizationFullWithCatalog(
      flowAPI,
      baseUrlForMappedServices,
      locationId,
      metaSiteId,
      instanceId,
      appId,
      language,
      isCatalogsV3,
    );

    // save organization full to cache, we should not wait for it.
    trySaveWarmupData(ORGANIZATION_FULL_WARMUPDATA_KEY, flowAPI, organizationFull);
  }

  const locale = organizationFull.restaurant.locale || 'en_US';
  const paymentMethods = !experiments.enabled('specs.restaurants.delaySetPayments')
    ? await getPaymentMethodsAndSetLoading(appId, instanceId, locale, (loading) =>
        store.dispatch(setLoadingPaymentMethods({ loading })),
      )
    : undefined;

  const data = flowAPI.controllerConfig.platformAPIs.storage.session.getItem(SESSION_STORAGE_KEY);
  loadStoredDataIntoStore(
    store,
    data,
    organizationFull.menu,
    organizationFull.catalog,
    isCatalogsV3,
    organizationFull.restaurant,
    flowAPI.environment.isMobile,
  );

  const hasMultipleLocations =
    isMultiLocationSite(organizationFull.restaurant) && organizationFull.locations.length > 1;

  store.dispatch(
    setIsMultiLocation({
      value: hasMultipleLocations,
    }),
  );

  paymentMethods && store.dispatch(setPaymentMethods({ paymentMethods }));
  store.dispatch(setOrganizationFull({ organizationFull }));
  paymentMethods !== undefined && store.dispatch(setLoadingPaymentMethods({ loading: false }));
  store.dispatch(initApp());

  return {
    language,
    store,
  };
}

function getViewMode(flowAPI: ControllerFlowAPI): IWixWindowViewMode {
  if (flowAPI.environment.isEditor) {
    return 'Editor';
  } else if (flowAPI.environment.isPreview) {
    return 'Preview';
  } else {
    return 'Site';
  }
}

function getLabelsSettings(styleParams: IWixStyleParams, defaultLabelsSettings?: LabelsSettings): LabelsSettings {
  if (_.isEmpty(styleParams.numbers) && _.isEmpty(styleParams.colors) && defaultLabelsSettings) {
    return defaultLabelsSettings;
  }

  const numbersStyleParams = styleParams?.numbers;
  const iconTypeId = numbersStyleParams['wixorders.icontype'];
  const colorTypeId = numbersStyleParams['wixorders.colortype'];

  const labelsSettings: LabelsSettings = { iconTypeId, colorTypeId };
  const colorsStyleParams = styleParams?.colors;
  if (colorsStyleParams) {
    labelsSettings.iconPrimaryColor = colorsStyleParams['wixorder.iconprimarycolor']?.value;
    labelsSettings.iconSecondaryColor = colorsStyleParams['wixorder.iconsecondarycolor']?.value;
    labelsSettings.iconCustomPrimaryColor = colorsStyleParams['wixorder.iconcustomprimarycolor']?.value;
    labelsSettings.iconCustomSecondaryColor = colorsStyleParams['wixorder.iconcustomsecondarycolor']?.value;
  }

  return labelsSettings;
}

function initializeStoreWithPlatformParams(store: Store<AppState, Action<any>>, flowAPI: ControllerFlowAPI) {
  const publicData = flowAPI.controllerConfig.config.publicData;
  const styleParams = flowAPI.controllerConfig.config.style.styleParams;
  store.dispatch(
    setPlatformParams({
      platformParams: {
        compId: flowAPI.controllerConfig.compId.toString(),
        compClassName: '',
        isMobile: flowAPI.environment.isMobile,
        instance: extractInstanceFromFlowAPI(flowAPI),
        settings: getSettingsValues(publicData, componentSettings, {
          isMobile: flowAPI.environment.isMobile,
        }),
        isRTL: flowAPI.environment.isRTL,
        viewMode: getViewMode(flowAPI),
        signedInstance: flowAPI.controllerConfig.appParams.instance,
        labelsSettings: getLabelsSettings(styleParams, store.getState().platformParams.labelsSettings),
        isEditorX: flowAPI.environment.isEditorX,
      },
    }),
  );
}

function getSignedInstance(flowAPI: ControllerFlowAPI) {
  return (
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(ORDERS_APP_ID) || flowAPI.controllerConfig.appParams.instance
  );
}

async function fetchRestaurantAPI(
  flowAPI: ControllerFlowAPI,
  baseUrlForMappedServices: string,
  locationId: string | undefined,
) {
  let organizationFull: OrganizationDeprecated | undefined;
  flowAPI.fedops.interactionStarted('fetch-restaurant');
  try {
    organizationFull = await fetchRestaurant(getSignedInstance(flowAPI), locationId, {
      host: baseUrlForMappedServices,
      preloadMenus: true,
      retry: {
        retries: flowAPI.environment.isEditor ? 3 : 0,
        onRetry: (_error, attempt) =>
          flowAPI.bi?.report(
            fetchOrganizationData({
              locationGuid: locationId,
              oloSessionId: SESSION_ID,
              retryNumber: attempt,
              timestampMs: Date.now(),
              viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
            }),
          ),
      },
    });
  } catch {
    organizationFull = undefined;
  }
  flowAPI.fedops.interactionEnded('fetch-restaurant');

  flowAPI.bi &&
    flowAPI.bi.report(
      fetchOrganizationData({
        locationGuid: locationId,
        oloSessionId: SESSION_ID,
        restaurantId: organizationFull?.restaurant.id,
        timestampMs: Date.now(),
        viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      }),
    );

  return organizationFull;
}

export function extractInstanceFromFlowAPI(flowAPI: ControllerFlowAPI): Instance {
  const signedInstance = flowAPI.controllerConfig.appParams.instance;
  return extractInstanceData(signedInstance);
}

async function renderSEO(flowAPI: ControllerFlowAPI, menu: Menu, catalog: Catalog, restaurant: Restaurant) {
  const { experiments } = flowAPI;
  const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');
  flowAPI.fedops.interactionStarted('render-time-seo-online-orders');

  const displayableMenu = isCatalogsV3
    ? getDisplayableCatalog(catalog, restaurant.locale, restaurant.currency, moment(), CatalogPlatform.SITE, 'takeout')
    : getDisplayableMenu(menu, restaurant.locale, restaurant.currency, moment(), 'web', 'takeout', 'catalog');

  const baseUrl = flowAPI.controllerConfig.wixCodeApi.location.baseUrl;

  const onlineOrderPageUrl = `${baseUrl}${flowAPI.controllerConfig.wixCodeApi.site?.currentPage?.url || ''}` || '';
  const seoRestaurant = getSeoRestaurant(restaurant, displayableMenu, baseUrl, onlineOrderPageUrl);

  await flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
    itemType: 'RESTAURANTS_ORDER_PAGE',
    itemData: seoRestaurant,
  });
  flowAPI.fedops.interactionEnded('render-time-seo-online-orders');
}

export function controllerFactory(setupFunction: (flowAPI: ControllerFlowAPI) => Promise<EnvironmentData>) {
  return async function createController({ controllerConfig, flowAPI }: ControllerParams): Promise<IWidgetController> {
    const { setProps } = controllerConfig;
    const { isMobile } = flowAPI.environment;

    const { store, language } = await setupFunction(flowAPI);
    initializeStoreWithPlatformParams(store, flowAPI);

    function dispatch(action: Action<any>) {
      store.dispatch(action);
    }

    if (flowAPI.bi) {
      flowAPI.bi.updateDefaults({
        oloSessionId: SESSION_ID,
        restaurantId: store.getState().session.restaurant.id,
        viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
        locationGuid: store.getState().session.restaurant.currentLocationId,
        visitor_id: undefined, // reset default values from yoshi
        projectName: undefined,
        appName: undefined,
      });
    }

    store.subscribe(() => {
      try {
        setProps({
          appState: store.getState(),
        });
      } catch (e) {
        console.warn('Error in controller: ', e);
      }
    });

    return {
      async pageReady() {
        const { menu, catalog, restaurant } = store.getState().session;
        await renderSEO(flowAPI, menu, catalog, restaurant);
        setProps({
          appState: store.getState(),
          appName: applicationJson.appName,
          language,
          mobile: isMobile,
          dispatch,
          shouldNotRenderMain:
            flowAPI.environment.isSSR &&
            flowAPI.controllerConfig.wixCodeApi.location.path.find((path) => path === 'checkout'),
          isRTL: flowAPI.environment.isRTL,
          basePath: `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}${
            flowAPI.controllerConfig.wixCodeApi.site?.currentPage?.url || ''
          }`,
          revalidateSession: async () => {
            await flowAPI.controllerConfig.wixCodeApi.site.loadNewSession();
            const newSignedInstance = flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(ORDERS_APP_ID);
            if (newSignedInstance) {
              dispatch(setSignedInstance({ signedInstance: newSignedInstance }));
              return newSignedInstance;
            }
          },
          fitToContentHeight: true,
        });
      },
      updateConfig: ($w, updatedConfig) => {
        const updatedPublicData = updatedConfig.publicData.COMPONENT || {};
        const updatedSettings = getSettingsValues(updatedPublicData, componentSettings, {
          isMobile: flowAPI.environment.isMobile,
        });
        const updatedStyles = updatedConfig.style.styleParams || {};

        store.dispatch(
          setPlatformParamsSettings({
            settings: updatedSettings,
          }),
        );
        store.dispatch(
          setPlatformParamsLabelsStyles({
            labelsSettings: getLabelsSettings(updatedStyles),
          }),
        );
      },
    };
  };
}
export default controllerFactory(setup);
