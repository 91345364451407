import moment from 'moment-timezone';
import {
  abortSmsVerification,
  clickGoToCheckout,
  initCheckout,
  loginMember,
  logoutMember,
  resetSubmitFailedIndication,
  setCashierPayment,
  setCashierPaymentDone,
  setCashierToken,
  setContact,
  setContactId,
  setDeliveryAddress,
  setDeliveryAddressAdditionalInfo,
  setDeliveryAddressComment,
  setDeliveryAddressFromForm,
  setDeliveryAddressLabel,
  setDeliveryAddressLine2,
  setDispatchTime,
  setDispatchType,
  setErrorType,
  setMemberContactLoading,
  setMembersAPiContact,
  setPayment,
  setSelectedAddressId,
  setSmsError,
  setSmsLoading,
  submitOrder,
  submitOrderFailed,
  submitSmsCode,
} from './checkout.actions';
import { ProbeArgument } from '../createStore';
import { orderSelector } from '../selectors/orderSelector';
import { cartSummarySelector } from '../selectors/cartSummarySelector';
import {
  closeModal,
  initApp,
  openModal,
  saveStateToSessionStorage,
  setIsUserLoggedIn,
} from '../session/session.actions';
import { Modals } from '../../core/constants';
import { addPendingOrderItemToCart, removeOrderCoupon } from '../cart/cart.actions';
import { SetCashierPaymentPayload, SetContactPayload } from './checkout.actions.types';
import {
  extractCashierResponse,
  getCashierCallbackUrl,
  isCashierApprovedServerStatus,
  isCashierFailedServerStatus,
  isCashierInProcessServerStatus,
  isCashierPendingServerStatus,
  parseCashierCallbackParams,
} from '../../core/logic/cashierLogic';
import { ServerPaymentStatus } from '@wix/cashier-payments-widget';
import uuid from 'uuid';
import {
  Action,
  BusinessNotifications,
  CashierPayment,
  Contact,
  DINE_IN,
  ErrorCodes,
  getDateOptions,
  getDefaultDispatchType,
  getDisplayableMenu,
  getDisplayableCatalog,
  getFinalAvailability,
  getTimeOptions,
  Menu,
  Order,
  OrderFailureResponse,
  PickupDispatch,
  shouldVerifyOrderWithSms,
  VirtualDispatchType,
  dtlv2_getDefaultDispatchType,
  dtlv2_getEarliestDispatchTime,
  getNumberOfDaysAheadAvailableForOrders,
  DateTimeOption,
  CatalogPlatform,
  isMultiLocationSite,
  currencySymbol as getCurrencySymbol,
  Catalog,
  ChargeV2,
  isSupportsFutureOrder,
} from '@wix/restaurants-client-logic';
import { isAvailableOn } from 'availability';
import { submitSmsVerification } from '../../core/oloApi';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { businessNotificationSelector } from '../selectors/businessNotificationSelector';
import {
  getMemberContactDetails,
  handleOrderFailure,
  handleOrderSuccess,
  isContactValid,
  reportSubmitOrderBiEvent,
  getItemNameAndId,
} from './checkout.probe.utils';
import { RestaurantsContacts, SubmitContactRequest } from '@wix/ambassador-restaurants-contacts/http';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { convertMembersAddressToOloAddress } from '../../core/logic/addressLogic';
import { getOrderPolicy } from '../../core/logic/policyLogic';

import { getDishPrepareTime } from '../../components/MainPage/components/DispatchTimeSelector/DispatchTimeSelector.helper';
import {
  getDineInInfo,
  getDispatchTypeFromVirtual,
  getVirtualDispatchTypeFromDispatch,
  isPickupUnavailableATM,
} from '../../core/logic/dispatchLogic';
import { Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { loyaltyProgramSelector } from '../selectors/loyaltyProgramSelector';
import { setSelectedAddress } from '../addressInformationForm/addressForm.actions';

import { selectLocations } from '../selectors/locationsSelector';
import { getSignedInstance } from '../session/session.probe';
import { fetchOrderPacingBusyBlocks } from '../../core/logic/orderPacingLogic';
import { getFirstAvailableTimeOption } from '../../core/helpers/timeOptions.helper';
import {
  addToCart,
  smsValidationDisplay,
  smsValidationFailure,
  smsValidationSuccess,
} from '@wix/bi-logger-olo-client/v2';
import { getServiceFeesBIEventParamsSelector } from '../selectors/serviceFeeBIEventSelector';

export default function checkoutProbe({ onAction, waitForAction }: ProbeArgument) {
  async function getCashierContinueStatus(dispatch: Dispatch<Action<any>>, token: string) {
    if (token) {
      dispatch(setCashierToken({ token }));
      const { payload }: ReturnType<typeof setCashierPaymentDone> = await waitForAction(
        setCashierPaymentDone.toString(),
      );
      return payload?.paymentResult?.status;
    }
  }

  onAction(submitOrder.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(resetSubmitFailedIndication());
    const state = getState();
    const { experiments } = flowAPI;
    const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');
    const { restaurant, menu, catalog, charges } = state.session;
    const isMultiLocation = isMultiLocationSite(restaurant);
    const rawOrder = orderSelector(state);
    const order = {
      ...rawOrder,
      ...(state.checkout.deliveryProvider
        ? {
            delivery: {
              ...rawOrder.delivery,
              deliveryProvider: {
                configurationId: state.checkout.deliveryProvider.configurationId,
                estimateId: state.checkout.deliveryProvider.estimateId,
              },
            },
          }
        : {}),
      contact: decorateContactWithWixIds(rawOrder.contact, flowAPI),
      serviceFees: state.checkout.calculatedFees,
      ...(isMultiLocation ? { locationId: restaurant.currentLocationId } : {}),
    };

    const { itemCount, priceComponents, redeemPoints } = cartSummarySelector(state);
    const requestId = uuid.v4();
    const isContactless = Boolean(state.checkout.isContactlessDeliveryChecked);

    const policy = getOrderPolicy(state.session.restaurant);
    const isConsentRequired = Boolean(policy);
    const isConsentCheckboxChecked = Boolean(policy?.policyCheckbox);
    const dispatchType = getVirtualDispatchTypeFromDispatch(state.checkout.dispatch);
    const deliveryDetails = order.delivery as PickupDispatch;
    const contactlessDineIn = dispatchType === 'dine-in' ? deliveryDetails.contactlessDineIn : undefined;
    const currencySymbol = getCurrencySymbol(restaurant.currency);
    const { tip } = state.checkout;
    // for BI - value of tip button selected
    const tipButtonValue = tip?.isCustom
      ? 'custom'
      : tip?.amount === 0
      ? 'none'
      : tip?.tipType === 'percent'
      ? `${tip?.amount}%`
      : tip && `${currencySymbol}${tip.amount / 100}`;
    const { isLoyaltyRedeemActive } = loyaltyProgramSelector(state);
    const loyaltyPoints = isLoyaltyRedeemActive ? redeemPoints : undefined;
    const contactId = state.checkout.contact.wixContactId;
    const tpaConfigurationId = _.get(order, 'delivery.deliveryProvider.configurationId');

    reportSubmitOrderBiEvent({
      flowAPI,
      restaurant,
      requestId,
      total: order.price,
      totalItemsCount: itemCount,
      isContactless,
      isConsentRequired,
      isConsentCheckboxChecked,
      loyaltyPoints,
      dispatchType,
      contactlessDineInInputLabel: contactlessDineIn?.label,
      contactlessDineInUOUInput: contactlessDineIn?.labelValue,
      discount_subtotal: priceComponents.discountSubtotal,
      tipType: tip?.tipType,
      tipSubtotal: tip?.amount,
      tipButtonValue,
      order,
      contactId,
      configurationId: tpaConfigurationId,
      deliveryFee: priceComponents?.deliveryFee,
      serviceFeeBIEventParams: getServiceFeesBIEventParamsSelector(state),
    });

    const coupon = getState().cart.coupon;

    if (coupon?.type === 'success') {
      try {
        const response = await fetch(
          `https://codeusages.wixrestaurants.com/v1/prefixes/${restaurant.id}/codes/${coupon.code}/tokens/${coupon.token}/canUse`,
        );
        const { canUse } = await response.json();

        if (!canUse) {
          dispatch(removeOrderCoupon());
          dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
          dispatch(submitOrderFailed());
          return;
        }
      } catch (e) {
        dispatch(removeOrderCoupon());
        dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
        dispatch(submitOrderFailed());
        return;
      }
    }
    let response;
    try {
      response = await fetch(`https://api.wixrestaurants.com/v2/organizations/${restaurant.id}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),
      });
    } catch (e) {
      handleOrderFailure({
        orderResponseType: 'no_server_response',
        dispatch,
        flowAPI,
        requestId,
        isDeliveryPartner: !!tpaConfigurationId,
      });
      dispatch(submitOrderFailed());
      return;
    }

    if (response.ok) {
      const orderResponse: Order = await response.json();
      const cashierResponse = extractCashierResponse(orderResponse);
      const continueStatus = await getCashierContinueStatus(dispatch, cashierResponse.responseToken);

      if (orderResponse.status === 'pending') {
        if (isCashierInProcessServerStatus(cashierResponse.status as ServerPaymentStatus)) {
          if (isCashierInProcessServerStatus(continueStatus as ServerPaymentStatus)) {
            dispatch(submitOrderFailed());
            return;
          }

          if (
            continueStatus &&
            (isCashierApprovedServerStatus(continueStatus as ServerPaymentStatus) ||
              isCashierPendingServerStatus(continueStatus as ServerPaymentStatus))
          ) {
            await fetch(
              `https://api.wixrestaurants.com/v2/organizations/${restaurant.id}/orders/${orderResponse.id}/confirmCashier?as=customer`,
              {
                method: 'POST',
              },
            );
          } else {
            dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
            dispatch(submitOrderFailed());
            return;
          }
        }
      }

      if (isCashierFailedServerStatus(continueStatus as ServerPaymentStatus)) {
        dispatch(setErrorType({ errorCode: ErrorCodes.USER_PAYMENT_CANCELED }));
        dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
        dispatch(submitOrderFailed());
        return;
      }

      if (shouldVerifyOrderWithSms(orderResponse)) {
        let isSmsVerificationSuccessful = false;
        dispatch(openModal({ modal: Modals.SMS_MODAL }));
        reportSmsBiEvent(flowAPI, 'display');

        while (!isSmsVerificationSuccessful) {
          const smsAction = await waitForAction([submitSmsCode.toString(), abortSmsVerification.toString()]);

          if (smsAction.type === submitSmsCode.toString()) {
            const code = smsAction.payload.code;
            dispatch(setSmsLoading({ loading: true }));
            isSmsVerificationSuccessful = await submitSmsVerification(restaurant.id, orderResponse.id, code);
            dispatch(setSmsLoading({ loading: false }));

            if (isSmsVerificationSuccessful) {
              dispatch(closeModal({ modal: Modals.SMS_MODAL }));
              reportSmsBiEvent(flowAPI, 'success');
            } else {
              dispatch(setSmsError({ hasSmsError: true }));
              reportSmsBiEvent(flowAPI, 'failure');
            }
          } else if (smsAction.type === abortSmsVerification.toString()) {
            // TODO: BI event
            dispatch(submitOrderFailed());
            return;
          }
        }
      }

      handleOrderSuccess({
        orderResponse,
        dispatch,
        flowAPI,
        requestId,
        restaurant,
        menu,
        catalog,
        charges,
        isCatalogsV3,
        isDeliveryPartner: Boolean(tpaConfigurationId),
      });
    } else {
      const orderResponse: OrderFailureResponse = await response.json();
      const { chargeId } = orderResponse.params || {};
      const isPOSError = orderResponse.type?.includes('pos_error');
      let orderErrorCode = orderResponse.errorCode;

      if (isPOSError && orderResponse.detail) {
        orderErrorCode = JSON.parse(orderResponse.detail).errors?.[0].code;
      }

      dispatch(submitOrderFailed());
      handleOrderFailure({
        orderResponseType: orderResponse.type,
        orderResponseDetail: orderResponse.detail,
        orderResponseCode: orderErrorCode,
        orderResponseParams: orderResponse.params,
        orderResponseChargeId: chargeId,
        tpaConfigurationId,
        dispatch,
        flowAPI,
        requestId,
        isDeliveryPartner: Boolean(tpaConfigurationId),
      });
    }
  });

  onAction(
    setCashierPayment.toString(),
    async (action: Action<SetCashierPaymentPayload>, getState, dispatch, { flowAPI }) => {
      const { instance } = getState().platformParams;
      const { paymentMethod, paymentMethodTitle = '' } = getState().checkout.pendingCashierPayment;
      const { priceComponents } = cartSummarySelector(getState());
      const accountId = `${instance.appDefId}:${instance.instanceId}`;
      const { paymentDetailsId, creditCard } = action.payload;
      if (paymentMethod) {
        const payment: CashierPayment = {
          type: 'cashier',
          paymentMethod,
          paymentMethodTitle,
          paymentDetailsId,
          accountId,
          amount: priceComponents.total,
          creditCard,
          returnUrl: getCashierCallbackUrl(flowAPI),
        };

        dispatch(
          setPayment({
            payment,
          }),
        );
      }
    },
  );

  onAction(addPendingOrderItemToCart.toString(), (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const { payments } = state.checkout;
    const { orderItems } = state.cart;
    const lastItemDetails = orderItems[orderItems.length - 1];
    const { priceComponents } = cartSummarySelector(getState());

    if (flowAPI.bi) {
      flowAPI.bi.report(addToCart({ dishId: lastItemDetails.itemId, quantity: lastItemDetails.count }));
    }

    if (payments.length === 1) {
      dispatch(
        setPayment({
          payment: {
            ...payments[0],
            amount: priceComponents.total,
          },
        }),
      );
    }
  });

  onAction(setDeliveryAddressFromForm.toString(), (action, getState, dispatch) => {
    dispatch(
      setDeliveryAddress({
        address: getState().addressForm.selectedAddressOption,
      }),
    );
  });

  onAction(setDeliveryAddressAdditionalInfo.toString(), (actions, getState, dispatch) => {
    const {
      selectedAddressOption: { addressLine2, comment, label },
    } = getState().addressForm;

    addressLine2 !== undefined && dispatch(setDeliveryAddressLine2({ addressLine2 }));
    comment !== undefined && dispatch(setDeliveryAddressComment({ comment }));
    label !== undefined && dispatch(setDeliveryAddressLabel({ label }));
  });

  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const { restaurant, menu, catalog, charges } = state.session;
    const { overrideDispatchType } = state.checkout;
    const dispatchTime = state.checkout.dispatch.time || Date.now();
    const platform = state.platformParams.isMobile ? CatalogPlatform.MOBILE_SITE : CatalogPlatform.SITE;
    const tpaConfigurationId = _.get(state.checkout.dispatch, 'deliveryProvider.configurationId');
    const { experiments } = flowAPI;
    const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');

    const displayableMenu = isCatalogsV3
      ? getDisplayableCatalog(
          catalog,
          restaurant.locale,
          restaurant.currency,
          moment(dispatchTime),
          platform,
          'delivery',
        )
      : getDisplayableMenu(
          menu,
          restaurant.locale,
          restaurant.currency,
          moment(dispatchTime),
          platform === CatalogPlatform.MOBILE_SITE ? 'mobileweb' : 'web',
          'delivery',
        );

    let dispatchType: VirtualDispatchType =
      getDefaultDispatchType({
        restaurant,
        dispatchTime,
        displayableMenu,
        shouldUseDefaultDispatchType: true,
        isSupportsFutureOrder: isSupportsFutureOrder(restaurant),
      }) ?? 'takeout';

    if (experiments.enabled('specs.restaurants.olo-client-dtl-v2')) {
      try {
        dispatchType = dtlv2_getDefaultDispatchType({ restaurants: selectLocations(getState()) });
      } catch (e) {
        console.warn(e);
      }
    }

    if (overrideDispatchType) {
      dispatchType = getVirtualDispatchTypeFromDispatch(state.checkout.dispatch);
    }

    const businessNotification = businessNotificationSelector(state);

    const isDeliveryType = dispatchType === 'delivery';

    const finalAvailability = getFinalAvailability(
      restaurant,
      getDispatchTypeFromVirtual(dispatchType),
      undefined,
      !isDeliveryType,
    );

    const dispatchTypeQuery = flowAPI.controllerConfig.wixCodeApi.location.query
      ? flowAPI.controllerConfig.wixCodeApi.location.query.dispatchType
      : undefined;

    const locationId = flowAPI.controllerConfig.wixCodeApi.location.query
      ? flowAPI.controllerConfig.wixCodeApi.location.query.locationId
      : undefined;

    const setFirstAvailableTimeOption = async (
      date: DateTimeOption,
      timeOptions: DateTimeOption[],
      dishPrepareTime: number,
    ) => {
      const signedInstance = getSignedInstance(flowAPI);
      const busyBlocks = await fetchOrderPacingBusyBlocks({
        signedInstance,
        locationId,
        fromTimestamp: date.timestamp,
        orderFulfillmentTimeInMinutes: dishPrepareTime,
        fedopsLogger: flowAPI.fedops,
      });

      const firstAvailableOption = getFirstAvailableTimeOption(timeOptions, busyBlocks);

      dispatch(
        setDispatchTime({
          timestamp: firstAvailableOption?.timestamp,
        }),
      );
    };

    if (
      restaurant.orders.asap.disabled ||
      businessNotification?.notification === BusinessNotifications.OnlyFutureOrders ||
      (!restaurant.orders.future.disabled &&
        !isDeliveryType &&
        !isAvailableOn(finalAvailability, moment().tz(restaurant.timezone)))
    ) {
      const dishPrepareTime = getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType));
      const numberOfDays = getNumberOfDaysAheadAvailableForOrders(restaurant.orders);

      const dateOptions = getDateOptions({
        availability: finalAvailability,
        timezone: restaurant.timezone,
        numberOfDays,
        delayMins: restaurant.orders.future.delayMins,
        dishPrepareTime,
        breakOnFirst: true,
      });

      if (dateOptions.length > 0) {
        const isOrderPacingEnabled = !experiments.enabled('specs.restaurants.disable-order-pacing');
        const timeOptions = getTimeOptions({
          availability: finalAvailability,
          timezone: restaurant.timezone,
          locale: restaurant.locale,
          day: moment(dateOptions[0].timestamp).tz(restaurant.timezone).startOf('day').valueOf(),
          delayMins: restaurant.orders.future.delayMins,
          dishPrepareTime,
          breakOnFirst: !isOrderPacingEnabled,
        });

        if (isOrderPacingEnabled) {
          setFirstAvailableTimeOption(dateOptions[0], timeOptions, dishPrepareTime);
        } else {
          dispatch(
            setDispatchTime({
              timestamp: timeOptions?.[0].timestamp,
            }),
          );
        }
      }
    }

    if (experiments.enabled('specs.restaurants.olo-client-dtl-v2')) {
      try {
        const { timingOption, timestamp } = dtlv2_getEarliestDispatchTime(
          restaurant,
          getDispatchTypeFromVirtual(dispatchType),
        );

        if (timingOption === 'future' && timestamp) {
          dispatch(
            setDispatchTime({
              timestamp,
            }),
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }

    dispatch(setMemberContactLoading({ loading: true }));
    const contact = await getMemberContactDetails(flowAPI);

    if (contact) {
      dispatch(setMembersAPiContact({ contact }));
    }
    dispatch(setMemberContactLoading({ loading: false }));
    const isMultiLocation = state.session.isMultiLocation;
    if (dispatchTypeQuery && dispatchTypeQuery === DINE_IN) {
      if (!getDineInInfo(restaurant)?.enabled) {
        dispatch(openModal({ modal: Modals.DINE_IN_CLOSED }));
      } else {
        if (!isMultiLocation && !isPickupUnavailableATM(restaurant.deliveryInfos, restaurant)) {
          dispatch(setDispatchType({ dispatchType: dispatchTypeQuery }));
          dispatch(openModal({ modal: Modals.DISPATCH_SETTINGS_MODAL }));
        } else {
          dispatch(setDispatchType({ dispatchType }));
        }
      }
    } else {
      dispatch(setDispatchType({ dispatchType }));
    }
    await handleCashierWalletCallback(
      flowAPI,
      dispatch,
      restaurant,
      menu,
      catalog,
      charges,
      isCatalogsV3,
      Boolean(tpaConfigurationId),
    );
  });

  onAction(initCheckout.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    dispatch(setMemberContactLoading({ loading: true }));
    const contact = await getMemberContactDetails(flowAPI);

    if (contact) {
      dispatch(setMembersAPiContact({ contact }));
    }
    dispatch(setMemberContactLoading({ loading: false }));

    const { savedAddresses, defaultAddressId } = state.session;
    const defaultAddress = savedAddresses.find((address) => address.id === defaultAddressId);
    if (defaultAddress && defaultAddress.addressLine1) {
      dispatch(setSelectedAddressId({ id: defaultAddressId }));
      dispatch(setSelectedAddress({ address: convertMembersAddressToOloAddress(defaultAddress) }));
      dispatch(
        setDeliveryAddress({
          address: convertMembersAddressToOloAddress(defaultAddress),
        }),
      );
    }
  });

  onAction(loginMember.toString(), async (action, getState, dispatch, { flowAPI }) => {
    await flowAPI.controllerConfig.wixCodeApi.user.promptLogin({});
  });

  onAction(logoutMember.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(setIsUserLoggedIn({ isLoggedIn: false }));
    await flowAPI.controllerConfig.wixCodeApi.user.logout();
  });

  onAction(clickGoToCheckout.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const { restaurant, menu, catalog } = state.session;
    const { experiments } = flowAPI;
    const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');
    state.platformParams.viewMode === 'Site' &&
      flowAPI.controllerConfig.wixCodeApi.window.trackEvent('InitiateCheckout', {
        origin: 'Restaurants',
        currency: restaurant.currency,
        contents: state.cart.orderItems.map((orderItem) => {
          const { itemName, itemId } = getItemNameAndId({
            orderItemId: orderItem.itemId,
            catalog,
            menu,
            restaurant,
            isCatalogsV3,
          });
          return {
            id: itemId,
            name: itemName,
            quantity: orderItem.count || 1,
            price: orderItem.price / 100,
          };
        }),
      });
    dispatch(saveStateToSessionStorage());
  });

  onAction(setContact.toString(), async (action: Action<SetContactPayload>, getState, dispatch, { flowAPI }) => {
    const { firstName, lastName, email, phone, wixContactId } = action.payload.contact;
    const shouldSubmitContact = !wixContactId;

    if (shouldSubmitContact && isContactValid({ email, phone })) {
      const signedInstance = getState().platformParams.signedInstance;
      const headers = { Authorization: signedInstance };
      const restaurantsContacts = RestaurantsContacts('/_api/restaurants').RestaurantsContacts()(headers);
      const submitContactRequest: SubmitContactRequest = { contact: { email, firstName, lastName, phone } };
      flowAPI.fedops.interactionStarted('submit-contact-id-checkout-flow');
      const result = await restaurantsContacts.submitContact(submitContactRequest);
      flowAPI.fedops.interactionEnded('submit-contact-id-checkout-flow');

      if (result?.contactId) {
        dispatch(setContactId({ wixContactId: result.contactId }));
        dispatch(saveStateToSessionStorage());
      }
    }
  });
}

function reportSmsBiEvent(flowAPI: ControllerFlowAPI, type: 'display' | 'success' | 'failure') {
  if (flowAPI.bi) {
    switch (type) {
      case 'display':
        flowAPI.bi.report(smsValidationDisplay({}));
        break;
      case 'failure':
        flowAPI.bi.report(smsValidationFailure({}));
        break;
      case 'success':
        flowAPI.bi.report(smsValidationSuccess({}));
        break;
      default:
        break;
    }
  }
}

async function handleCashierWalletCallback(
  flowAPI: ControllerFlowAPI,
  dispatch: any,
  restaurant: Restaurant,
  menu: Menu,
  catalog: Catalog,
  charges: ChargeV2[],
  isCatalogsV3: boolean,
  isDeliveryPartner: boolean,
) {
  const { isCashierCallback, restaurantId, orderId, ownerToken, status } = parseCashierCallbackParams(flowAPI);

  if (!isCashierCallback) {
    return;
  }

  flowAPI.controllerConfig.wixCodeApi.location.queryParams.remove([
    'cashierCallback',
    'transactionStatus',
    'restaurantsId',
    'orderId',
    'ownerToken',
  ]);

  if (!isCashierApprovedServerStatus(status as ServerPaymentStatus)) {
    handleOrderFailure({ isDeliveryPartner, orderResponseType: 'order_status_not_approved', dispatch, flowAPI });
    return;
  }

  let response;

  try {
    response = await fetch(
      `https://api.wixrestaurants.com/v2/organizations/${restaurantId}/orders/${orderId}?viewMode=customer`,
      {
        headers: {
          authorization: `Bearer ${ownerToken}`,
        },
      },
    );
  } catch (e) {
    handleOrderFailure({ isDeliveryPartner, orderResponseType: 'no_server_response', dispatch, flowAPI });
    return;
  }

  const orderResponse = await response.json();

  handleOrderSuccess({
    orderResponse,
    dispatch,
    flowAPI,
    restaurant,
    menu,
    catalog,
    charges,
    isCatalogsV3,
    isDeliveryPartner,
  });
}

function decorateContactWithWixIds(contact: Contact, flowAPI: ControllerFlowAPI): Contact {
  const result = _.cloneDeep(contact);

  if (result.wixContactId) {
    const { role, id } = flowAPI.controllerConfig.wixCodeApi.user.currentUser;

    if (role === 'Visitor') {
      result.wixVisitorId = id;
    } else {
      result.wixMemberId = id;
    }
  }

  return result;
}
