import _ from 'lodash';
import { AppState } from '../createStore';
import combinedReducers from '../combinedReducers';
import diffByRef from './diffByRef';

export type StateVersion = {
  [key: string]: number;
};

export default function stateVersionReducer(
  prevState: AppState | undefined,
  nextState: ReturnType<typeof combinedReducers> & { [key: string]: any },
): StateVersion {
  const stateVersion = (prevState?.stateVersion ?? {}) as StateVersion;
  const diffPaths = diffByRef(_.omit(prevState, 'stateVersion'), _.omit(nextState, 'stateVersion'));

  const nextStateVersion: StateVersion = {};
  for (const path of diffPaths) {
    // value was changed - increment the version number
    nextStateVersion[path] = _.isNil(stateVersion[path]) ? 0 : stateVersion[path] + 1;
  }

  return { ...stateVersion, ...nextStateVersion };
}
