import { Instance } from '@wix/restaurants-client-logic';

export function extractInstanceData(signedInstance: string): Instance {
  const { metaSiteId, appDefId, instanceId, aid, siteOwnerId, siteIsTemplate } = JSON.parse(
    atob(signedInstance.split('.')[1]),
  );

  return { metaSiteId, appDefId, instanceId, aid, siteOwnerId, siteIsTemplate };
}

export const EMPTY_INSTANCE: Instance = Object.freeze({
  metaSiteId: '',
  appDefId: '',
  instanceId: '',
  aid: '',
  siteOwnerId: '',
});
