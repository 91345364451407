import { BusyBlock } from '@wix/ambassador-restaurants-order-pacing-pacer/types';
import { DateTimeOption } from '@wix/restaurants-client-logic';

export const getFirstAvailableTimeOption = (
  timeOptions: DateTimeOption[],
  busyBlocks?: BusyBlock[] | null,
): DateTimeOption | undefined => {
  if (!timeOptions || timeOptions.length === 0) {
    return;
  }
  if (!busyBlocks || busyBlocks.length === 0) {
    return timeOptions[0];
  }

  const busyBlocksTimeStamps = busyBlocks.map((busyBlock) => busyBlock.startTime?.getTime());

  return timeOptions.find((timeOption) => !busyBlocksTimeStamps.includes(timeOption.timestamp));
};
