import { useEnvironment } from '@wix/yoshi-flow-editor';

type ControllerEnvironment = ReturnType<typeof useEnvironment>;
interface getBaseUrlForMappedServicesArguments {
  websiteUrl: string;
  environment: Partial<Pick<ControllerEnvironment, 'isEditor' | 'isEditorX' | 'isSSR' | 'isPreview' | 'isViewer'>>;
}

export function getBaseUrlForMappedServices({ websiteUrl, environment }: getBaseUrlForMappedServicesArguments) {
  if (environment.isEditor) {
    return 'https://editor.wix.com';
  } else if (environment.isPreview) {
    return '';
  } else if (environment.isSSR || !websiteUrl) {
    return 'https://www.wix.com';
  } else {
    const url = new URL(websiteUrl);
    return `${url.protocol}//${url.host}`;
  }
}
