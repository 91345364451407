import { createStore, applyMiddleware, Middleware, compose } from 'redux';
import reduxProbeFactory, { ProbeArgument as OrignProbeArgument } from '../core/redux-probe/redux-probe';
import checkoutProbe from './checkout/checkout.probe';
import cartProbe from './cart/cart.probe';
import sessionProbe from './session/session.probe';
import addressFormProbe from './addressInformationForm/addressForm.probe';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import rootReducer from './rootReducer';
import { logger, showLogger } from './loggerMiddleware';
import { Action } from '@wix/restaurants-client-logic';

export type AppState = ReturnType<typeof rootReducer>;
export interface ProbeContext {
  flowAPI: ControllerFlowAPI;
}

export type ProbeArgument = OrignProbeArgument<Action<any>, AppState, ProbeContext>;

export function createConfiguredStore(initialState: AppState | undefined, probeContext: ProbeContext) {
  const storeEnhancer = createStoreEnhancer(probeContext);
  const composeEnhancers =
    typeof window !== 'undefined'
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true }) || compose
      : compose;
  return createStore(rootReducer, initialState, composeEnhancers(storeEnhancer));
}

function createStoreEnhancer(probeContext: ProbeContext) {
  const probeMiddleware = reduxProbeFactory<Action<any>, AppState, ProbeContext>(
    [checkoutProbe, cartProbe, sessionProbe, addressFormProbe],
    probeContext,
  );

  const middlewares: Middleware[] = [probeMiddleware];

  if (showLogger) {
    middlewares.push(logger('controller store'));
  }

  return applyMiddleware(...middlewares);
}
