import { Catalog } from '@wix/restaurants-client-logic';
import { listCatalogs, listMenus, listSections, listItems } from '@wix/ambassador-restaurants-catalogs-v3-catalog/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function fetchCatalog(flowAPI: ControllerFlowAPI, locationId?: string) {
  let catalog: Catalog;

  try {
    const catalogs = await fetchListCatalogs(flowAPI);
    const catalogProperties = catalogs?.find((cat) => cat.locationId === locationId);
    const catalogId = catalogProperties?.id || '';

    const [menus, sections, items] = await Promise.all([
      fetchListMenus(flowAPI, catalogId),
      fetchListSections(flowAPI, catalogId),
      fetchListItems(flowAPI, catalogId),
    ]);

    catalog = { menus: menus || [], sections: sections || [], items: items || [], metadata: catalogProperties };
  } catch (e) {
    throw new Error(`CANNOT FETCH CATALOG: ${e}`);
  }

  return catalog;
}

export async function fetchListCatalogs(flowAPI: ControllerFlowAPI) {
  const data = (await flowAPI.httpClient?.request(listCatalogs({})))?.data;
  const catalogs = data?.catalogs;

  return catalogs;
}

export async function fetchListMenus(flowAPI: ControllerFlowAPI, catalogId: string) {
  const data = (await flowAPI.httpClient?.request(listMenus({ catalogId })))?.data;
  const menus = data?.menus;

  return menus;
}

export async function fetchListSections(flowAPI: ControllerFlowAPI, catalogId: string) {
  const data = (await flowAPI.httpClient?.request(listSections({ catalogId })))?.data;
  const sections = data?.sections;

  return sections;
}

export async function fetchListItems(flowAPI: ControllerFlowAPI, catalogId: string) {
  const data = (await flowAPI.httpClient?.request(listItems({ catalogId })))?.data;
  const items = data?.items;
  return items;
}
