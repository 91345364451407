import _ from 'lodash';

// Returns a list of paths, where each path is the longest path where both states
// are unequal by ref
//
// Subtrees whose all possible paths are different are merged, and only the
// subtree's root is listed as different
// e.g.
// {} vs { foo: { bar: 1, baz: 2} } => ['foo']
export default function diffByRef(state1: any, state2: any, maxDepth?: number) {
  const diffs = recur(state1, state2, maxDepth);
  return _.without(diffs, CanMerge);
}

// Acts as a symbol (no need for a polyfill)
const CanMerge = {};

function recur(state1: any, state2: any, maxDepth = Infinity, curDepth = 0, curPath: string[] = [], diffs: any[] = []) {
  const keys = _.union(_.keys(state1), _.keys(state2));
  const paths = curPath.length > 0 ? curPath.join('.') : keys;

  if (curDepth > maxDepth) {
    return state1 !== state2 ? diffs.concat(paths).concat(CanMerge) : diffs;
  }

  // Same ref or value - consider as equal and don't compare their subtrees
  if (state1 === state2) {
    return diffs;
  }

  // Arrays with unequal lengths - consider as different
  if (Array.isArray(state1) && Array.isArray(state2) && state1.length !== state2.length) {
    return diffs.concat(paths).concat(CanMerge);
  }

  // Different types or primitives/functions - consider as different
  if (typeof state1 !== typeof state2 || typeof state1 !== 'object') {
    return diffs.concat(paths).concat(CanMerge);
  }

  // state1 and state2 are object/arrays with different refs - compare their subtrees.
  // Subtree can be merged into its root iff all of its leaves are unequal.
  let canMerge = true;
  const subtreeDiffs = [];

  for (const key of keys) {
    const path = curPath.concat(key);

    let childDiffs = recur(state1[key], state2[key], maxDepth, curDepth + 1, path, []);

    if (_.last(childDiffs) !== CanMerge) {
      canMerge = false;
    } else {
      childDiffs = childDiffs.slice(0, childDiffs.length - 1);
    }

    subtreeDiffs.push(...childDiffs);
  }

  if (canMerge) {
    // discard subtreeDiffs - only the root (current path) is marked as different.
    diffs = diffs.concat(paths);
  } else {
    diffs = diffs.concat(subtreeDiffs);
  }

  return diffs;
}
