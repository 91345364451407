import moment from 'moment-timezone';
import {
  Action,
  Catalog,
  DisplayableMenu,
  getDisplayableMenu,
  getDisplayableCatalog,
  Menu,
  Restaurant,
  CatalogPlatform,
} from '@wix/restaurants-client-logic';
import { navigate } from '../session/session.actions';
import { RouteUrls } from '../../core/constants';
import { setOrderItems } from './cart.actions';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Dispatch } from 'redux';

export function skipToCheckout({
  flowAPI,
  menu,
  catalog,
  isCatalogsV3,
  restaurant,
  platform,
  dispatchTime,
  dispatch,
}: {
  flowAPI: ControllerFlowAPI;
  menu: Menu;
  catalog: Catalog;
  isCatalogsV3: boolean;
  restaurant: Restaurant;
  platform: CatalogPlatform;
  dispatchTime: number;
  dispatch: Dispatch<Action<any>>;
}) {
  const displayableMenu = isCatalogsV3
    ? getDisplayableCatalog(catalog, restaurant.locale, restaurant.currency, moment(dispatchTime), platform, 'delivery')
    : getDisplayableMenu(
        menu,
        restaurant.locale,
        restaurant.currency,
        moment(dispatchTime),
        platform === CatalogPlatform.MOBILE_SITE ? 'mobileweb' : 'web',
        'delivery',
      );

  const itemIdsQuery = flowAPI.controllerConfig.wixCodeApi.location.query
    ? flowAPI.controllerConfig.wixCodeApi.location.query.testItemId
    : undefined;

  if (!itemIdsQuery) {
    return;
  }

  const items = getDisplayableMenuItems(displayableMenu, itemIdsQuery.split(','));

  if (items.length > 0) {
    dispatch(
      setOrderItems({
        orderItems: items.map((item) => ({
          count: 1,
          itemId: item.id,
          price: item.price || 0,
        })),
      }),
    );
    dispatch(navigate({ routeUrl: RouteUrls.CHECKOUT_FLOW }));
  }
}

function getDisplayableMenuItems(displayableMenu: DisplayableMenu, itemIds: string[]) {
  const arr = [];
  for (const menu of displayableMenu) {
    for (const section of menu.sections) {
      for (const item of section.items) {
        if (itemIds.indexOf(item.id) !== -1) {
          arr.push(item);
        }
      }
    }
  }

  return arr;
}
